<template>
  <div>
    <div class="partenaire-content">
      <h2 style="color: #139864;">{{ $t('partenaire.titre') }}</h2>
      <p style="color: #139864;">{{ $t('partenaire.sous_titre') }}</p>
      <div class="partenaire-body">
        <!-- Slide Partenaire section -->
        <div class="slide-container">
          <SlidePartenaire />
        </div>
        <!-- Visitors info section -->
        <div class="visitor-info">
          <h2 style="color: #139864;font-size: 60px;">{{ visitor }}</h2>
          <p style="color: #139864;font-size: 20px;">VISITEURS</p>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import SlidePartenaire from '../other/slidePartenaire.vue';
import axiosClient from "@/axiosClient";
export default {
  name: 'CptPartenaire',
  components: {
    SlidePartenaire,
  },
  data() {
    return {
      country: "",
      deviceType: "",
      visitTime: "",
      ip: "",
      lat: "",
      long: "",
      operateur: "",
      visitor:"",
      pays: "",
    };
  },
  computed: {
    // Formatte le nombre de visiteurs pour l'affichage
    formattedVisitor() {
      return this.visitor.toLocaleString(); // Ajoute une virgule si le nombre est grand
    },
  },
  mounted() {
    this.getVisitor();
  },
  methods: {
    async getUserCountry() {
      try {
        const response = await fetch("http://ip-api.com/json/");
        const data = await response.json();
        console.log(data);

        this.country = data.country;
        
        
        this.ip = data.query;
        this.lat = data.lat;
        this.long = data.long;
        this.operateur = data.org;
      } catch (error) {
        console.error("Erreur lors de la récupération du pays:", error);
      }
    },
    detectDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      if (/mobile|android|iphone|ipad|tablet/.test(userAgent)) {
        this.deviceType = "Mobile";
        console.log("mobile", this.deviceType);
      } else {
        this.deviceType = "PC";
        console.log("PC", this.deviceType);
      }
    },
    getVisitTime() {
      const currentTime = new Date();
      this.visitTime = currentTime.toLocaleTimeString(); // Heure locale de l'utilisateur
      console.log("heure", this.visitTime);
    },
    startVisitorCounter() {
      const stepTime = 50; // Temps entre chaque incrément
      const incrementValue = Math.ceil(this.targetVisitor / 50); // Valeur d'incrément

      const counterInterval = setInterval(() => {
        if (this.visitor < this.targetVisitor) {
          this.visitor = Math.min(this.visitor + incrementValue, this.targetVisitor);
        } else {
          clearInterval(counterInterval); // Arrête le compteur une fois la cible atteinte
        }
      }, stepTime);
    },
    async getVisitor() {
      try {
        // Obtenir l'information du visiteur à partir de l'API
        const response = await fetch("http://ip-api.com/json/");
        const data = await response.json();
        
        this.country = data.country;
        this.ip = data.query;
        this.lat = data.lat.toString();
        this.long = data.lon.toString();
        this.operateur = data.org;
      } catch (error) {
        console.error("Erreur lors de la récupération du pays:", error);
      }

      try {
        const visitorData = {
          ip_address: this.ip, 
          operateur: this.operateur, 
          pays: this.country, 
          device: this.deviceType, 
          long: this.long, 
          lat: this.lat, 
        };

        const response = await axiosClient.post("/get-visitor-ip", visitorData);

        // Assigner la valeur des visiteurs récupérée
        this.targetVisitor = response.data.nb_vues; 
        this.startVisitorCounter(); // Démarrer le compteur
      } catch (error) {
        console.error("Erreur lors de la récupération des informations du visiteur", error);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.partenaire-content {
  max-width: var(--max-width);
  margin: 0 auto;
  height: auto;
  color: var(--blanc);
  padding-bottom: 20px;
}

.partenaire-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.slide-container {
  flex: 3; /* Slide should take most of the space */
  max-width: 70%; /* Limit slide width to prevent overflow */
}

.visitor-info {
  flex: 1; /* Visitor info takes less space */
  text-align: center;
  margin-left: 20px;
}

.visitor-info h2 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}

.visitor-info p {
  margin: 0;
  font-size: 18px;
}

.partenaire-content h2 {
  text-align: center;
  padding: 20px;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
}

.partenaire-content p {
  padding: 10px;
  text-align: center;
}
</style>
