<template>
  <div>
    <div class="content">
      <h2>{{ $t('description.titre') }}</h2>
      <div class="accordion">

        <!-- Premier accordéon -->
        <div class="accordion-item" @click="toggleAccordion(1)">
          <div class="accordion-header">
            <h4 style="color:#139864;"> <i class="fa-solid fa-seedling fa-1x"></i> {{ $t('description.vision_titre') }}</h4>
           
            <i :class="isAccordionOpen(1) ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"></i>
          </div>
          <div class="accordion-content" :class="{ open: isAccordionOpen(1) }" v-if="isAccordionOpen(1)">
            <p>{{ $t('description.vision_corps') }}</p>
          </div>
        </div>

        <!-- Deuxième accordéon -->
        <div class="accordion-item" @click="toggleAccordion(2)">
          <div class="accordion-header">  
            <h4  style="color: #139864;"><i class="fa-solid fa-cow fa-1x"></i> {{ $t('description.mission_titre') }}</h4>
            <i :class="isAccordionOpen(2) ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"></i>
          
          </div>
          <div class="accordion-content" :class="{ open: isAccordionOpen(2) }" v-if="isAccordionOpen(2)">
            <p>{{ $t('description.mission_corps') }}</p>
          </div>
        </div>

        <!-- Troisième accordéon -->
        <div class="accordion-item" @click="toggleAccordion(3)">
          <div class="accordion-header">
            <h4  style="color: #4F8FF5;"><i class="fa-solid fa-fish fa-1x"></i> {{ $t('description.valeur_titre') }}</h4>
            <i :class="isAccordionOpen(3) ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"></i>
            
          </div>
          <div class="accordion-content" :class="{ open: isAccordionOpen(3) }" v-if="isAccordionOpen(3)">
            <p>{{ $t('description.valeur_corps') }}</p>
          </div>
        </div>

        <!-- Quatrième accordéon -->
        <div class="accordion-item" @click="toggleAccordion(4)">
          <div class="accordion-header">
            
            <h4  style="color: #FFA800;"><i class="fa-solid fa-globe fa-1x"></i> {{ $t('description.environnement') }}</h4>
            <i :class="isAccordionOpen(4) ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'"></i>
          </div>
          <div class="accordion-content" :class="{ open: isAccordionOpen(4) }" v-if="isAccordionOpen(4)">
            <p>Contenu de l'environnement</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CptDescription',

  data() {
    return {
      openAccordion: null // Stocke l'état des accordéons
    };
  },

  methods: {
    toggleAccordion(index) {
      // Permet d'ouvrir ou de fermer un accordéon
      this.openAccordion = this.openAccordion === index ? null : index;
    },

    isAccordionOpen(index) {
      // Vérifie si l'accordéon est ouvert
      return this.openAccordion === index;
    }
  },
};
</script>

<style lang="css" scoped>
.content {
  max-width: var(--max-width);
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.content h2 {
  text-align: center;
  padding: 20px;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
  color: var(--vert);
}
.accordion {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
  align-items: flex-start; /* Alignement en haut pour que chaque accordéon garde sa propre hauteur */
}

.accordion-item {
  width: 48%;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px 0;
  padding: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.accordion-item:hover {
  background-color: #f0f0f0;
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-header h4 {
  margin-left: 20px;
  font-size: x-large;
}

.accordion-content {
  padding: 15px;
  border-top: 1px solid #ddd;
  max-height: 0; /* Hauteur initiale fermée */
  overflow: hidden; /* Masquer le contenu quand l'accordéon est fermé */
  transition: max-height 0.3s ease; /* Transition pour l'animation de l'ouverture/fermeture */
}

.accordion-content.open {
  max-height: 500px; /* Hauteur maximum lors de l'ouverture, à ajuster selon ton contenu */
}

@media (max-width: 768px) {
  .accordion-item {
    width: 100%;
  }
}


</style>
