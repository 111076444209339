<template>
  <div>
    <Loader v-if="isLoading"></Loader>
    <div v-else>

      <NavBarConnect class="Acc-headd"/>
      <!-- :textes="textes" :titres="titres" -->
      <Header  :slides="slides"  :height="63" />
      <Corps />
      <Top />
    </div>
  </div>
</template>

<script>
import axiosClient from '@/axiosClient';
import Loader from '@/components/other/loader.vue';

import Header from '@/components/section/header.vue';
import Corps from '@/components/corps.vue';
import Top from '@/components/other/icone.vue';
import NavBarConnect from '@/components/section/navbarconnect.vue';

export default {
  name: 'ViewsAccueil',
  components: {
    Header,
    Loader,
    Corps,
    Top,
    NavBarConnect,
  },
  data() {
    return {
      slides: [],
      isLoading: false,
    };
  },
  async mounted() {
    await this.getProduits();
  },
  methods: {
    async getProduits() {
      try {
        const response = await axiosClient.get(
          '/enquetes/marches-prix/prix-des-deux-dernieres/par-produit'
        );
        this.slides = response.data;
        this.isLoading = false; // Les données sont chargées
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style>
body.loading {
  overflow: hidden;
  transition: 3s ease-in-out;
}
</style>
