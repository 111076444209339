<template>
  <!-- <div>
      {{slide}}
    </div> -->
  <div>
    <transition :name="transitionEffect">
      <div
        class="carousel-item"
        v-show="currentSlide === index"
        @mouseenter="$emit('mouseenter')"
        @mouseout="$emit('mouseout')"
      >
        <div class="image">
           <img v-if="slide.image === null" src="@/assets/images/logo-sim.jpg" alt="slide" />
           <img :src="slide.marches[0].prices[0].image" alt="slide" /> 
        </div>
        <div class="Acc-texte">
          <div class="tableau">
            <section class="table__header">
              <h1>
                <i class="fas fa-search"></i>
                <router-link to="/#recherche" class="Acc-lien">
                
                </router-link>
                <carousel-indicator>
                  <!-- Icône de produit -->
                 
                  <span> {{ slide.nom_produit }} </span>
                  
                </carousel-indicator>
              </h1>
            </section>
            <section class="table__body">
              <table>
                <thead>
                  <tr>
                    <th style="flex-wrap: nowrap !important"></th>
                    <th
                      v-for="(marche, index) in slide.marches"
                      :key="'market-' + index"
                    >
                      {{ marche.marche.nom_marche }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="flex-wrap: nowrap !important" class="title">
                      {{ $t("slide.prix_actuel") }}
                    </td>
                    <td
                      v-for="(marche, index) in slide.marches"
                      :key="'prix-actuel-' + index"
                    >
                      <template v-if="marche.prices.length >= 1">
                        {{ formatBudget(marche.prices[0].prix_kg) ?? "-" }}
                      </template>
                      <template v-else>-</template>
                    </td>
                  </tr>
                  <tr>
                    <td class="title">{{ $t("slide.date_actuel") }}</td>
                    <td
                      v-for="(marche, index) in slide.marches"
                      :key="'date-actuelle-' + index"
                    >
                      <template v-if="marche.prices.length >= 1">
                        {{ dateFormat(marche.prices[0].date_enquete) ?? "-" }}
                      </template>
                      <template v-else>-</template>
                    </td>
                  </tr>
                  <tr>
                    <td class="title">{{ $t("slide.prix_precedent") }}</td>
                    <td
                      v-for="(marche, index) in slide.marches"
                      :key="'prix-precedent-' + index"
                    >
                      <template v-if="marche.prices.length > 1">
                        {{ formatBudget(marche.prices[1].prix_kg) ?? "-" }}
                      </template>
                      <template v-else>-</template>
                    </td>
                  </tr>
                  <tr>
                    <td class="title">{{ $t("slide.date_precedent") }}</td>
                    <td
                      v-for="(marche, index) in slide.marches"
                      :key="'date-precedente-' + index"
                    >
                      <template v-if="marche.prices.length > 1">
                        {{ dateFormat(marche.prices[1].date_enquete) ?? "-" }}
                      </template>
                      <template v-else>-</template>
                    </td>
                  </tr>
                  <tr>
                    <td class="title">{{ $t("slide.evolution") }}</td>
                    <td
                      v-for="(marche, index) in slide.marches"
                      :key="'date-precedente-' + index"
                    >
                      <template v-if="marche.prices.length > 1">
                        <span
                          :class="{
                            'text-green':
                              getDifference(
                                marche.prices[1].prix_kg,
                                marche.prices[0].prix_kg
                              ) < 0,
                            'text-red':
                              getDifference(
                                marche.prices[1].prix_kg,
                                marche.prices[0].prix_kg
                              ) > 0,
                          }"
                        >
                          <!-- Affiche la flèche et le pourcentage -->

                          <i
                            :class="{
                              'fa fa-arrow-down':
                                getDifference(
                                  marche.prices[1].prix_kg,
                                  marche.prices[0].prix_kg
                                ) < 0,
                              'fa fa-arrow-up':
                                getDifference(
                                  marche.prices[1].prix_kg,
                                  marche.prices[0].prix_kg
                                ) > 0,
                            }"
                          ></i>
                          {{
                            formatBudget(
                              getDifference(
                                marche.prices[1].prix_kg,
                                marche.prices[0].prix_kg
                              )
                            )
                          }}%
                        </span>
                      </template>
                      <template v-else>-</template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  emits: ["mouseenter", "mouseout"],

  // , 'texte', 'localites'
  props: ["slide", "currentSlide", "index", "direction"],
  data() {
    return {};
  },

  methods: {
    getImage(path) {
      try {
        return this.slide.startsWith("http")
          ? this.slide
          : require("@/assets/" + path);
      } catch (e) {
        // return require('@/assets/naniens/no.png')
      }
    },
    formatBudget(value) {
      let Formatter = new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return Formatter.format(value);
    },
    dateFormat(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const year = String(d.getFullYear());

      return `${day}/${month}/${year}`;
    },
    getDifference(ancien, nouveau) {
      if (
        ancien === undefined ||
        nouveau === undefined ||
        ancien === 0 ||
        nouveau === 0
      ) {
        return 0;
      } else {
        const difference = parseFloat(nouveau) - parseFloat(ancien);
        return ((difference / parseFloat(ancien)) * 100).toFixed(2);
      }
    },
  },
  computed: {
    transitionEffect() {
      return this.direction === "right" ? "slide-out" : "slide-in";
    },
  },
};
</script>

<style scoped>
.carousel-item {
  position: relative;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: var(--blanc);
}

.Acc-texte {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 100;
  width: 60%;
  color: var(--noir);
  height: 100%;
  text-align: center;
  padding: 5px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.animated {
  transition: color 0.5s ease, transform 0.5s ease;
}
.text-red {
  color: red;
}
.text-green {
  color: green;
}

.tableau {
  width: 100%;
  box-shadow: var(--shadow-small);
  background-color: var(--blanc);
}

.title {
  text-align: left;
  font-weight: bold;
}

.table__body {
  max-height: 600px;
  overflow-y: auto;
  /* Permettre le défilement vertical si le contenu dépasse */
  margin: 0.5rem auto;
}

.table__header {
  padding: 10px 5px 0;
}

.table__header h1 {
  font-size: 28px;
  font-weight: bold;
  color: red;
}

.carousel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 2;
}

.carousel-control.left {
  left: 0;
}

.carousel-control.right {
  right: 0;
}

thead th {
  padding: 6px;
  border: 1px solid;
}

table,
th,
td {
  padding: 7px;
  font-size: 16px;
}

thead th,
tbody td {
  padding: 10px;
  /* Ajoute un espacement intérieur */
  border: 1px solid #ccc;
  /* Ajoute des bordures pour mieux visualiser les cellules */
  white-space: nowrap;
  /* Empêche le texte de se briser sur plusieurs lignes */
  text-align: center;
  /* Aligne le texte à gauche */
  overflow: hidden;
  /* Cache tout contenu qui déborde */
  text-overflow: ellipsis;
  /* Ajoute des points de suspension si le texte est trop long */
}

.carousel-item .image {
  position: absolute;
  top: 50%;
  right: -16%;
  transform: translate(-50%, -50%);
  width: 32%;
  height: 88%;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table__header i {
    position: absolute;
    top: 4px;
    left: 5px;
    /* cursor: pointer; */
    visibility:visible; 
    margin-left: 90px;
    top: 20px;
    color: black;
}
.image img {
  width: 255px;
  border: 5px solid hsla(240, 7%, 97%, 1);
  height: 230px;
}

.slide-in-enter-active,
.slide-in-leave-active,
.slide-out-enter-active,
.slide-out-leave-active {
  transition: all 1s ease-in-out;
}

.slide-in-enter-from {
  transform: translateX(-100%);
}

.slide-in-leave-to {
  transform: translateX(100%);
}

.slide-out-enter-from {
  transform: translateX(100%);
}

.slide-out-leave-to {
  transform: translateX(-100%);
}

@media (max-width: 1000px) {
  td:not(:first-of-type) {
    min-width: 7rem;
  }
}

@media (max-width: 960px) {
  td:not(:first-of-type) {
    min-width: 4rem;
  }
}

@media (max-width: 800px) {
  .carousel-item .image {
    display: none;
  }

  .Acc-texte {
    width: 100%;
  }
}

@media (max-width: 600px) {
  td:not(:first-of-type) {
    min-width: 0rem;
  }

  table,
  th,
  td {
    font-size: 12px;
    /* Réduire la taille du texte pour les petits écrans */
  }

  .table__body {
    max-height: 300px;
    /* Ajuster la hauteur pour les petits écrans */
  }
}
</style>
