<template>
  <div class="header">
    <div class="header-top">
      <div class="logo-section" style="margin-left: 40px">
        <img
          src="@/assets/images/ANASA.jpeg"
          alt="logo"
          height="30"
          class="logo"
        />
      </div>
      <div class="logo-section">
        <img
          src="@/assets/images/sim-guinee.jpg"
          alt="logo1"
          height="50"
          class="logo"
        />
        <!-- Hauteur ajustée -->
      </div>

      <div class="header-title">
        <h1 class="title">RÉPUBLIQUE DE GUINÉE</h1>
        <hr class="dashed-line" />
        <div class="motto">
          <span class="motto-item red">Travail</span>
          <span class="motto-item yellow">Justice</span>
          <span class="motto-item green">Solidarité</span>
        </div>
        <h2 class="subtitle">MINISTÈRE DE L'AGRICULTURE ET DE L'ÉLEVAGE</h2>
        <h3 class="agency-name">
          Agence Nationale des Statistiques Agricoles et Alimentaires (ANASA)
        </h3>
      </div>

      <div
        class="logo-section"
        style="display: flex; justify-content: center; align-items: end"
      >
        <img src="@/assets/images/gn.png" alt="ministry logo" class="logo" />
        <img
          src="@/assets/images/armoirie1.png"
          alt="Guinea flag"
          class="logo1"
        />
      </div>
    </div>

    <!-- <div class="guinea-bar">
      <div class="bar red"></div>
      <div class="bar yellow"></div>
      <div class="bar green"></div>
    </div> -->
    <nav class="Acc-nav">
      <div class="container-nav">
        <ul class="Acc-menu">
          <li class="visitor-count">
            <i class="fa fa-eye"></i> {{ visitor }} Vues
          </li>
          <li>
            <router-link to="/" class="Acc-lien">{{
              $t("navbar.accueil")
            }}</router-link>
          </li>
          <li>
            <router-link to="/#stock" class="Acc-lien">{{
              $t("navbar.prix")
            }}</router-link>
          </li>
          <li>
            <router-link to="/#about" class="Acc-lien">{{
              $t("navbar.cles")
            }}</router-link>
          </li>
          <li>
            <router-link to="/#magasin" class="Acc-lien">{{
              $t("navbar.magasin")
            }}</router-link>
          </li>
          <li>
            <router-link to="/#recherche" class="Acc-lien">
              <i class="fas fa-search"></i>
              <!-- Icône de recherche -->
              {{ $t("navbar.rechercher") }}
            </router-link>
          </li>

          <li>
            <router-link to="/#partenaire" class="Acc-lien">{{
              $t("navbar.partenaire")
            }}</router-link>
          </li>
          <li>
            <router-link to="/#contact" class="Acc-lien">{{
              $t("navbar.contact")
            }}</router-link>
          </li>
          <!-- <li>
            <button>
              telecharger
            </button>
            
            
          </li> -->
        </ul>
        <div class="language-section">
          <div @click="toggleLanguageDropdown" class="select">
            {{ getLanguageName(selectedLanguage) }}
            <img :src="getFlagSrc(selectedLanguage)" class="image" alt="" />
          </div>
          <div v-show="isLanguageDropdownOpen" class="select_content">
            <div
              v-for="(language, index) in languages"
              :key="index"
              @click="changeLanguage(language.value)"
              class="select_item"
            >
              {{ language.name }}
              <img :src="language.flagSrc" class="image" alt="logo_flag" />
            </div>
          </div>
          <div class="burger">
            <div class="line1"></div>
            <div class="line1"></div>
            <div class="line1"></div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import axiosClient from "@/axiosClient";
export default {
  name: "CptNavBar",
  data() {
    return {
      selectedLanguage: "fr",
      country: "",
      deviceType: "",
      visitTime: "",
      ip: "",
      lat: "",
      long: "",
      operateur: "",
      pays: "",
      languages: [
        {
          value: "fr",
          name: "Français",
          flagSrc: require(`@/assets/images/france-flag-icon.svg`),
        },
        {
          value: "en",
          name: "English",
          flagSrc: require(`@/assets/images/unit.svg`),
        },
      ],
      isLanguageDropdownOpen: false,
      isAuthenticated: false,
      visitor: "",
    };
  },

  methods: {
    toggleLanguageDropdown() {
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
    },
    changeLanguage(language) {
      this.selectedLanguage = language;
      this.$i18n.locale = language;
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
    },
    async getUserCountry() {
      try {
        const response = await fetch("http://ip-api.com/json/");
        const data = await response.json();
        console.log(data);

        this.country = data.country;
        
        
        this.ip = data.query;
        this.lat = data.lat;
        this.long = data.long;
        this.operateur = data.org;
      } catch (error) {
        console.error("Erreur lors de la récupération du pays:", error);
      }
    },
    detectDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      if (/mobile|android|iphone|ipad|tablet/.test(userAgent)) {
        this.deviceType = "Mobile";
        console.log("mobile", this.deviceType);
      } else {
        this.deviceType = "PC";
        console.log("PC", this.deviceType);
      }
    },
    getVisitTime() {
      const currentTime = new Date();
      this.visitTime = currentTime.toLocaleTimeString(); // Heure locale de l'utilisateur
      console.log("heure", this.visitTime);
    },

    getFlagSrc(language) {
      return this.languages.find((l) => l.value === language).flagSrc;
    },
    getLanguageName(language) {
      return this.languages.find((l) => l.value === language).name;
    },
    async getVisitor() {
      try {
        const response = await fetch("http://ip-api.com/json/");
        const data = await response.json();
        console.log(data);

        this.country = data.country;
        
        
        this.ip = data.query;
        this.lat = data.lat.toString();
        this.long = data.lon.toString();
        this.operateur = data.org;
      } catch (error) {
        console.error("Erreur lors de la récupération du pays:", error);
      }
      console.log("pays", this.country);
      try {
        const visitorData = {
          ip_address: this.ip, // Remplace par la valeur réelle de l'IP si tu l'as
          operateur: this.operateur, // Remplace par l'opérateur de l'utilisateur si nécessaire
          pays: this.country, // Le pays récupéré depuis une autre méthode (par exemple avec ip-api)
          device: this.deviceType, // Type de l'appareil (mobile ou PC)
          long: this.long, // Longitude si tu as un service de géolocalisation
          lat: this.lat, // Latitude si tu as un service de géolocalisation
        };

        const response = await axiosClient.post("/get-visitor-ip", visitorData);

        this.visitor = response.data.nb_vues; // Adapter selon la réponse de l'API
      } catch (error) {
        this.element = !this.element;
        console.error(
          "Erreur lors de la récupération des informations du visiteur",
          error
        );
      }
    },
  },
  mounted() {
    const burger = document.querySelector(".burger");
    const Navmenu = document.querySelector(".Acc-menu");
    burger.addEventListener("click", () => {
      burger.classList.toggle("burger-active");
      Navmenu.classList.toggle("Acc-menu-active");
    });
    console.log(localStorage.getItem("authToken"));
  
    this.detectDeviceType();
    this.getVisitor();
    if (localStorage.getItem("authToken")) {
      this.isAuthenticated = "true";
    }
  },
};
</script>
<style scoped>
.header {
  width: 100%;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-bottom: 2px solid #ddd;
}

.logo-section img {
  /* width: 80px !important; */
  cursor: pointer;
}

.logo-section1 img {
  width: 100px;
  cursor: pointer;
}

.logo-section,
.logo-section1 {
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-section img,
.logo-section1 img {
  height: auto;
  /* Garde les proportions des logos */
}

.logo1 {
  max-width: 60px;
  /* Réglage spécifique pour l'image "logo1" */
  height: auto;
}

.header-title {
  text-align: center;
  flex-grow: 1;
  margin-top: 5px;
  /* Ajustez le haut pour déplacer le titre */
}

.title {
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 4px;
}

.motto {
  /* margin-top: 10px; */
  font-size: 16px;
  font-weight: bold;
}

.motto-item {
  margin: 0 5px;
}
.visitor-count {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: #ed1b24;
  position: absolute;
  top: 10px;
  left: 30px;
}
.red {
  color: #ce1126;
}
.Acc-lien i {
  margin-right: 3px; /* Espace entre l'icône et le texte */
}

.yellow {
  color: #fcd116;
}

.green {
  color: #009460;
}

.subtitle {
  font-size: 16px;
  margin: 5px 0;
  text-transform: uppercase;
}

.agency-name {
  font-size: 14px;
  margin: 0;
}

.header-right {
  display: flex;
  align-items: center;
}

.logo {
  width: 140px;
  /* height: 130px; */
  margin-right: 15px;
}
.logo-section img[alt="logo1"] {
  height: 80px; /* Ajuster la taille */
  width: 180px;
}

.dashed-line {
  border: none;
  border-bottom: 2px dashed black;
  width: 10%;
  margin: 5px auto;
}

.guinea-flag {
  width: 50px;
}

.logo-section {
  display: flex;
  align-items: center;
}

.texte {
  margin-left: 10px;
  font-family: "Playfair Display", serif;
}

.header-title {
  text-align: center;
}

.header-title h1 {
  font-size: 1.5rem;
  margin: 0;
}

.header-title h2 {
  font-size: 1.2rem;
  margin: 0;
  color: black;
  font-weight: bolder !important;
}

.guinea-bar {
  display: flex;
  height: 5px;
  width: 100%;
}

.bar {
  flex: 1;
}

.red {
  color: #ce1126;
}

.yellow {
  color: #fcd116;
}

.green {
  color: #009460;
}

.Acc-nav {
  background-color: white;
  color: #139864;
  font-size: large;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.container-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 10px;
}

.Acc-menu {
  display: flex;
  gap: 30px;
  list-style: none;
}

.Acc-lien {
  color: #139864;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.Acc-lien:hover {
  color: #ffa800;
}

.language-section {
  display: flex;
  align-items: center;
}

.select {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #139864;
}

.select img {
  height: 13px;
  margin-left: 5px;
}

.select_content {
  position: absolute;
  background-color: white;
  box-shadow: var(--box-shadow);
  border-radius: 6px;
  padding: 10px;
  top: 50px;
  right: 0;
  width: 150px;
  z-index: 10;
}

.select_item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: #000000;
  cursor: pointer;
}

.select_item:hover {
  background-color: #d0f0e0;
}

.burger div {
  background-color: #ffffff;
}

.image {
  width: 30px;
  height: 30px;
}

@media (max-width: 880px) {
  .burger {
    display: block;
    cursor: pointer;
  }

  .Acc-menu {
    position: absolute;
    top: 100px;
    left: 0;
    flex-direction: column;
    background-color: var(--blanc);
    width: 100%;
    display: none;
    text-align: center;
  }

  .burger.burger-active + .Acc-menu {
    display: flex;
  }
}
</style>
