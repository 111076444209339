<template>
  <div class="carousel" ref="crsl">
    <!-- Affiche l'indicateur de chargement reçu depuis le parent -->
    <div v-if="slides.length==0? isLoading:false" class="loader">
      <LoaderTable/>
    </div>

    <!-- Affiche le carousel lorsque le chargement est terminé -->
    <div v-else class="carousel-inner">
      <!-- Carousel indicators -->
      <carousel-indicators 
        v-if="indicators" 
        :total="slides.length" 
        :current-index="currentSlide" 
        @switch="switchSlide($event)"
      ></carousel-indicators>
      
      <!-- Carousel items -->
      <carousel-item 
        v-for="(slide, index) in slides" 
        :key="`item-${index}`" 
        :slide="slide" 
        :current-slide="currentSlide" 
        :index="index" 
        :direction="direction"
        @mouseenter="stopSlideTimer" 
        @mouseleave="startSlideTimer"
      ></carousel-item>
      
      <!-- Carousel controls -->
      <carousel-controls 
        v-if="controls" 
        @prev="prev" 
        @next="next"
      ></carousel-controls>
    </div>
  </div>
</template>

<script>
import CarouselItem from "@/components/Accueil/banner/carouselItem.vue";
import CarouselControls from "@/components/Accueil/banner/carouselControls.vue";
import CarouselIndicators from "@/components/Accueil/banner/carouselIndicators.vue";
import LoaderTable from '@/components/other/loaderTable.vue';
export default {
  name: "CptCarousel",
  props: {
    slides: {
      type: Array,
      required: true,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    indicators: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: Number,
      default: 10000, // 10 secondes
    },
    // isLoading: {
    //   type: Boolean,
    //   required: true, // Reçu du parent pour indiquer le chargement
    // }
  },
  components: { CarouselItem, CarouselControls, CarouselIndicators,LoaderTable },
  data() {
    return {
      currentSlide: 0,
      slideInterval: null,
      direction: "right",
      isLoading: true
    };
  },
  methods: {
    setCurrentSlide(index) {
      this.currentSlide = index;
    },
    prev(step = -1) {
      const index = this.currentSlide > 0 ? this.currentSlide + step : this.slides.length - 1;
      this.setCurrentSlide(index);
      this.direction = "left";
      this.startSlideTimer();
    },
    next(step = 1) {
      const index = this.currentSlide < this.slides.length - 1 ? this.currentSlide + step : 0;
      this.setCurrentSlide(index);
      this.direction = "right";
      this.startSlideTimer();
    },
    startSlideTimer() {
      this.stopSlideTimer(); // Arrêter le précédent timer
      this.slideInterval = setInterval(() => {
        this.next();
      }, this.interval); // Utiliser l'intervalle correct (10 secondes)
    },
    stopSlideTimer() {
      if (this.slideInterval) {
        clearInterval(this.slideInterval); // Arrêter le timer en cours
        this.slideInterval = null;
      }
    },
    switchSlide(index) {
      const step = index - this.currentSlide;
      if (step > 0) {
        this.next(step);
      } else {
        this.prev(step);
      }
    },
  },
  mounted() {
    this.startSlideTimer();
  },
  beforeUnmount() {
    this.stopSlideTimer(); // S'assurer que le timer est arrêté lorsque le composant est détruit
  },
};
</script>

<style scoped>
.carousel {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.carousel-inner {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.loader {
  font-size: 1.5rem;
  text-align: center;
  padding: 20px;
  width: 100%;
}
</style>
